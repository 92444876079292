/**
 * Get a site from a list of sites by the site id.
 * @param {string} siteId - a site's id.
 * @param {array} sites - a list of site objects.
 * @returns {object} - the matching site object.
 */
export const getSiteById = (siteId, sites) => {
  if (!siteId || !sites) return null
  return sites.find(_site => _site.id === siteId)
}

/**
 * Retrieve a site name for a given site id, provided a list of sites.
 * @param {string} siteId - a site's id.
 * @param {array} sites - a list of site objects.
 * @returns {string} - the site name
 */
export const getSiteNameById = (siteId, sites) => {
  const site = getSiteById(siteId, sites)
  return site ? site.name : ''
}

/**
 * Get a site's active protocol version, provided an id and list of sites.
 * @param {*} siteId - a site's id.
 * @param {*} sites - a list of site objects.
 * @returns {number} - the site's active protocol version
 */
export const getSiteProtocolVersionById = (siteId, sites) => {
  const site = getSiteById(siteId, sites)
  return (site && site.protocolVersions.length > 0)
    ? site.protocolVersions.reduce((highestVersion, studyProtocolVersion) => {
      return parseFloat(studyProtocolVersion.protocolVersion.versionName) > highestVersion
        ? parseFloat(studyProtocolVersion.protocolVersion.versionName)
        : highestVersion
    }, 0)
    : 0
}
