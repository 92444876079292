import GET_SITES_QUERY from '@/graphql/sites/GetSitesQuery.graphql'
import { getSiteById, getSiteNameById, getSiteProtocolVersionById } from '@/ppmi/sites'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    sites() {
      return {
        query: GET_SITES_QUERY,
        variables: {
          studyId: this.$route.params.studyId
        },
        update: data => data.getSites,
        error (error) {
          logError(error, 'sites.js sites query')
        }
      }
    }
  },
  data() {
    return {
      sites: []
    }
  },
  methods: {
    getSiteFromId(siteId) {
      return getSiteById(siteId, this.sites)
    },

    getSiteName(siteId) {
      return getSiteNameById(siteId, this.sites)
    },

    getSiteProtocolVersion(siteId) {
      return getSiteProtocolVersionById(siteId, this.sites)
    }
  }
}
