<template>
  <div>
    <Modal
      title="Did Not Complete Visit"
      class="skip-visit-modal"
      @close="$emit('close')"
    >
      <template v-slot:content>
        <p>Please select the reason that best describes why this visit was not completed.</p>
        <BfSelect
          v-model="reasonSelected"
          label="Reason"
          placeholder="Select reason"
          popper-class="visit-not-complete-reason-dropdown"
        >
          <el-option
            v-for="(reason, index) in reasonsNotCompleted"
            :key="index"
            :label="reason.reason"
            :value="reason.reason"
          />
        </BfSelect>
        <ValidationProvider
          ref="reasonsNotCompleteValidator"
          v-slot="{ errors }"
          :rules="`${showReasonComment ? 'required' : ''}`"
        >
          <BfTextArea
            v-if="showReasonComment"
            v-model="reasonNotCompleteComment"
            :error="errors[0]"
            label="Other reason:"
          />
        </ValidationProvider>
        <label class="confirmation-label">Confirmation</label>
        <p class="confirmation-subtext">
          Marking a visit as “Did Not Complete” cannot be undone.
        </p>
        <BfCheckbox
          v-model="confirmAction"
          label="Confirm visit was not completed."
        />
      </template>
      <template v-slot:actions>
        <el-button
          :disabled="isSkipVisitDisabled"
          type="primary"
          @click="skipVisit"
        >
          Submit
        </el-button>
        <el-button
          type="outline"
          @click="$emit('close')"
        >
          Cancel
        </el-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfSelect from '@/components/BfSelect/BfSelect'
import BfTextArea from '@/components/BfTextArea/BfTextArea'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'

import VISIT_SKIP_REASONS_QUERY from '@/graphql/visits/VisitSkipReasonsQuery.graphql'
import START_VISIT_MUTATION_V2 from '@/graphql/visits/StartVisitV2Mutation.graphql'
import SET_VISIT_NOT_COMPLETING_MUTATION_V2 from '@/graphql/visits/SetVisitNotCompletingMutationV2.graphql'
import { VisitStatus } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { propEq } from 'ramda'

export default {
  components: {
    Modal,
    BfSelect,
    BfTextArea,
    BfCheckbox
  },
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  apollo: {
    reasonsNotCompleted() {
      return {
        query: VISIT_SKIP_REASONS_QUERY,
        update: data => data.getVisitSkippedReasons,
        error (error) {
          logError(error, 'SkipVisitModal.vue visit skip reasons query')
        }
      }
    }
  },
  data() {
    return {
      reasonsNotCompleted: [],
      reasonSelected: null,
      reasonNotCompleteComment: '',
      confirmAction: false
    }
  },
  computed: {
    isSkipVisitDisabled() {
      return !this.confirmAction || !this.reasonSelected ||
        (this.showReasonComment && !this.reasonNotCompleteComment.trim())
    },
    showReasonComment() {
      const reason = this.reasonsNotCompleted.find(propEq('reason', this.reasonSelected))
      return reason && reason.commentRequired
    }
  },
  methods: {
    skipVisit() {
      if (this.visit.visitInstance) {
        this.setVisitWithInstanceAsDidNotComplete()
      } else {
        this.setVisitWithoutInstanceAsDidNotComplete()
      }
    },

    /**
     * Execute the mutation to mark a visit with an existing instance as "Did Not Complete"
     */
    setVisitWithInstanceAsDidNotComplete() {
      // set mutation variables
      const variables = {
        visitId: this.visit.visitId,
        siteId: this.$route.params.siteId,
        reason: this.reasonSelected
      }
      if (this.showReasonComment) {
        variables.comment = this.reasonNotCompleteComment
      }

      // perform the mutation
      this.$apollo.mutate({
        mutation: SET_VISIT_NOT_COMPLETING_MUTATION_V2,
        variables: variables
      }).then(response => {
        this.$emit('close')
      }).catch(error => {
        logError(error, 'SkipVisitModal.vue set visit not completing mutation')
      })
    },

    /**
     * Execute the mutation to mark a visit without an instance as "Did Not Complete"
     */
    setVisitWithoutInstanceAsDidNotComplete() {
      const variables = {
        participantId: this.$route.params.participantId,
        protocolVersionId: this.visit.visitTemplate?.protocolVersion?.id,
        visitId: this.visit?.visitId,
        status: VisitStatus.NOT_COMPLETING,
        reason: this.reasonSelected
      }

      if (this.showReasonComment) {
        variables.comment = this.reasonNotCompleteComment
      }

      this.$apollo.mutate({
        mutation: START_VISIT_MUTATION_V2,
        variables: variables
      }).then(response => {
        this.$emit('close')
      }).catch(error => {
        logError(error, 'SkipVisitModal.vue start visit mutation')
      })
    }
  }
}
</script>

<style lang="scss">
.skip-visit-modal {
  p {
    margin: 0;
  }

  .confirmation-label,
  .form-field:not(.bf-checkbox) .input-label {
    display: block;
    margin-top: 1.5rem;
    @include text-style('interface', 'medium', 'medium');
  }

  .confirmation-subtext {
    display: block;
    margin: .25rem 0 .5rem;
    @include text-style('interface', 'small', 'regular');
    color: $hillcock;
  }

  .bf-checkbox.form-field {
    margin-bottom: 0;

    .input-label {
      margin: 0;
    }
  }
}
</style>
